import React, { lazy, Suspense } from "react";
import { useRoutes, Navigate, useLocation } from "react-router-dom";


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

export default function RoutesComponent() {
  const routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { element: <Home replace />, index: true },
        { path: "features", element: <Features /> },
        { path: "review", element: <Review /> },
        { path: "privacy_policy", element: <Privacy /> },
        { path: "terms_condition", element: <Terms /> },

      ],
    },
  ]);

  return routes;
}

// components
const Layout = Loadable(lazy(() => import("../layout/Layout")));
const Home = Loadable(lazy(() => import("../pages/Home")));
const Features = Loadable(lazy(() => import("../pages/Features")));
const Review = Loadable(lazy(() => import("../pages/Review")));
const Privacy = Loadable(lazy(() => import("../pages/Privacy")));
const Terms = Loadable(lazy(() => import("../pages/Terms")));
