import React from "react";
import RoutesComponent from './routes/Routes';


function App() {
  return (
    <>
    <RoutesComponent />
     {/* <AdsCompo adUnitId="ca-app-pub-7842443993349666/9258986815"/> */}
   </>
  );
}

export default App;
